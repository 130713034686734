import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Clipboard } from "@angular/cdk/clipboard";
import { HelperService } from "app/services/helper/helper.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { AngularFirestore } from "@angular/fire/firestore";
import { AuthService } from "app/services/auth/auth.service";
import { FormBuilder, Validators } from "@angular/forms";
import { masterCountries } from "app/shared/master-countries";
import { Observable } from "rxjs";
import { AngularFireStorage } from "@angular/fire/storage";
import { finalize } from "rxjs/operators";
import { UserData } from "app/models/userData.model";
import { EmailService } from "app/services/email/email.service";

@Component({
  selector: "app-verification-modal",
  templateUrl: "./verification-modal.component.html",
  styleUrls: ["./verification-modal.component.scss"],
})
export class VerificationModalComponent implements OnInit {
  errorMessage: string | null = null;
  uploadProgress: Observable<number> | null = null;
  downloadURL: Observable<string> | null = null;
  user: UserData;

  countryList = masterCountries;
  submit: boolean = false;
  regForm = this.fb.group({
    country: ["United States of America", [Validators.required]],
    address: ["", [Validators.required]],
    phone_number: ["", [Validators.required]],
    identityType: [null, [Validators.required]],
    identityImage: ["", [Validators.required]],
  });

  constructor(
    public dialogRef: MatDialogRef<VerificationModalComponent>,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private emailService: EmailService,
    private _helper: HelperService,
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private auth: AuthService
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.auth.user$.subscribe((data) => {
      this.user = data;
    });
  }

  no() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  requestVerification() {
    if (this.regForm.invalid) {
      this._helper.showError("Please fill all required information", "Error!");
      return;
    }
    this.submit = true;
    this.afs
      .collection("users")
      .doc(this.user.uid)
      .set({...this.regForm.value, requestedVerification: 1}, { merge: true })
      .then(() => {
        this._helper.showSuccess(
          "You will be confirmed within the next 24hrs",
          "Verification Request Sent!"
        );
        this.submit = false;
        this.emailService.kycSubmitNotification(
          this.user.displayName,
          this.user.email
        );
        this.close();
      })
      .catch((error) => {
        this._helper.showError(
          error,
          "Error sending request, please contact admin"
        );
        this.submit = false;
      });
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input?.files && input.files.length > 0) {
      const file = input.files[0];
      const validTypes = [
        "image/png",
        "image/jpeg",
        "image/gif",
        "application/pdf",
      ];

      if (validTypes.includes(file.type)) {
        this.uploadFile(file);
        this.errorMessage = null; // Clear any error
      } else {
        this.errorMessage = "Only images (PNG, JPG, GIF) and PDFs are allowed.";
      }
    }
  }

  uploadFile(file: File): void {
    const filePath = `uploads/${Date.now()}_${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // Monitor upload progress
    this.uploadProgress = task.percentageChanges();

    // Get the download URL after the upload completes
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe((data) => {
            console.log("downloadURL", data);
            this.regForm.get("identityImage").patchValue(data);
          });
        })
      )
      .subscribe();
  }
}
