import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ROUTES } from '../../sidebar/sidebar.component';
import { Router } from '@angular/router';
import { Location} from '@angular/common';
import { AuthService } from 'app/services/auth/auth.service';
import { ConfirmComponent } from 'app/components/confirm/confirm.component';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationService } from 'app/services/notification/notification.service';
import {Observable} from "rxjs";
import { HelperService } from 'app/services/helper/helper.service';

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['navbar.component.scss']
})

export class NavbarComponent implements OnInit{
    private listTitles: any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;
    public isCollapsed = true;
    @ViewChild("navbar-cmp", {static: false}) button;

    notification: Observable<any[]>
    notificationCount: number = 0

    constructor(
      location:Location,
      private renderer : Renderer2,
      private element : ElementRef,
      private router: Router,
      public auth: AuthService,
      private matDialog: MatDialog,
      private notify: NotificationService,
      public _helper: HelperService
      ) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;

        this.auth.user$.subscribe((data) => {
          if(data){
            this.notification = this.notify.notification
            this.notification.subscribe((data) => {
              if(data){
                let count = 0
                data.forEach((result) => {
                  if(!result.read){
                    count++
                  }
                })
                this.notificationCount = count
              }
            })
          }
        })

    }

    ngOnInit(){
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        var navbar : HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        this.router.events.subscribe((event) => {
          this.sidebarClose();
       });
    }
    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }
      return 'Dashboard';
    }
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
      }
      sidebarOpen() {
          const toggleButton = this.toggleButton;
          const html = document.getElementsByTagName('html')[0];
          const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
          setTimeout(function(){
              toggleButton.classList.add('toggled');
          }, 500);

          html.classList.add('nav-open');
          if (window.innerWidth < 991) {
            mainPanel.style.position = 'fixed';
          }
          this.sidebarVisible = true;
      };
      sidebarClose() {
          const html = document.getElementsByTagName('html')[0];
          const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
          if (window.innerWidth < 991) {
            setTimeout(function(){
              mainPanel.style.position = '';
            }, 500);
          }
          this.toggleButton.classList.remove('toggled');
          this.sidebarVisible = false;
          html.classList.remove('nav-open');
      };
      collapse(){
        this.isCollapsed = !this.isCollapsed;
        const navbar = document.getElementsByTagName('nav')[0];
        console.log(navbar);
        if (!this.isCollapsed) {
          navbar.classList.remove('navbar-transparent');
          navbar.classList.add('bg-white');
        }else{
          navbar.classList.add('navbar-transparent');
          navbar.classList.remove('bg-white');
        }

      }

      signout(){
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          title: 'logout',
          subtitle: 'Are you sure you want to logout?'
        };
        dialogConfig.autoFocus = false;
        let dialogRef = this.matDialog.open(ConfirmComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {

          if(result){
            this.auth.signOut()
          }
        });
      }

      goToNotifyLink(id, url){
        this.notify.read(id)
        this.router.navigate([url])
      }

}
