import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

interface MailData {
  message: string;
  subject: string;
  supportEmail: string;
  appName: string;
  name: string;
  email: string;
}

@Injectable({
  providedIn: "root",
})
export class EmailService {
  mailData = {
    supportEmail: "support@klavea.com",
    appName: "Klavea",
  };

  constructor(private _http: HttpClient) {}

  sendVerificationMail(name, email: string, code: string) {
    const subject = `Email Verification`;
    const message = `<p> Welcome to ${this.mailData.appName}</p>
    <p>We're thrilled to have you on board. To get started and enjoy all the features we offer, we'll need to verify your email address.</p>
      <p>Please use the below code to gain access to your account </p>
      <br>
      <h2><strong>${code}</strong></h2>
      <br>`;
    this.sendMail({ ...this.mailData, email, subject, message, name });
  }

  sendPasswordRecoveryMail(name, email: string, code: string) {
    const subject = `Password Recovery`;
    const message = `<p>You asked to reset the password to your ${this.mailData.appName} account. </p>
       <p>Please use the below code to reset your password</p>
       <br>
      <h2><strong>${code}</strong></h2>
      <br>`;
    this.sendMail({ ...this.mailData, email, subject, message, name });
  }

  async kycSubmitNotification(name, email) {
    const subject = `KYC notification from ${this.mailData.appName}`;
    const message = `<p>Thank you for submitting your KYC information. Our team is reviewing your details and will notify you of any updates or required actions.</p>
    <p>Your compliance is vital to maintaining the security of our services. For any questions, feel free to contact us.</p>`;
    this.sendMail({ ...this.mailData, email, subject, message, name });
  }

  private sendMail(data: MailData) {
    const body = this.template(data);
    const subject = data.subject;
    const email = data.email;
    let headers = new HttpHeaders();
    headers.set("Accept", "application/json");

    this._http
      .post(
        "https://mail.klavea.com",
        { body, subject, to: email },
        { headers }
      )
      .subscribe();
  }

  private template(data: MailData) {
    return `
    <html>
  <head>
    <meta name='viewport' content='width=device-width, initial-scale=1.0' />
    <meta charset='UTF-8' />
    <title>Transactional Email</title>
  </head>
  <body
    style='background-color: F1F3F4; font-family: sans-serif; font-size: 14px; line-height: 1.4; margin: 0; padding: 0;'
  >
    <table
      role='presentation'
      border='0'
      cellpadding='0'
      cellspacing='0'
      width='100%'
      bgcolor='F1F3F4'
      style='padding: 10px;'
    >
      <tr>
        <td align='center'>
          <table role='presentation' width='580' style='max-width: 100%;'>
            <tr>
              <td>
                <div
                  style='background-color: #3CA5FF; padding: 20px; border-radius: 20px 20px 0 0;'
                >
                  <h1 style="color:#fff;">Klavea.</h1>
                  <h2 style="color:#fff;"><strong>${data.subject}</strong></h2>
                  <p style="color:#fff;">Hi ${data.name},</p>
                </div>

                <div
                  style='background-color: #fff; color: #333333; padding: 20px; border-radius: 0 0 20px 20px;'
                >

                  <p>${data.message}</p>


                </div>

                <div
                  style='margin-top: 35px; color: #000000; padding: 10px; text-align: center; border-radius: 0 0 20px 20px; font-size: 12px;'
                >
                  <p style='margin: 15px;'><b>Follow Us:</b></p>
                  <a
                    href='#'
                    style='color: #000000; text-decoration: none; padding: 0 10px;'
                  ><img
                      width='30'
                      src='https://nearpaysstorage.blob.core.windows.net/nearpayscontainer/facebook_(1)_1730893781338.png'
                      alt='x'
                    /></a>
                  <a
                    href='#'
                    style='color: #000000; text-decoration: none; padding: 0 10px;'
                  ><img
                      width='30'
                      src='https://nearpaysstorage.blob.core.windows.net/nearpayscontainer/twitter_1730893562130.png'
                      alt='x'
                    /></a>
                  <a
                    href='#'
                    style='color: #000000; text-decoration: none; padding: 0 10px;'
                  ><img
                      width='30'
                      src='https://nearpaysstorage.blob.core.windows.net/nearpayscontainer/instagram_(1)_1730893865790.png'
                      alt='x'
                    /></a>
                  <p style='text-align; margin-top: 40px; color: #000000'>If you
                    dont recognize this activity, please report to us
                    immediately at
                    <a
                      href='mailto:${data.supportEmail}'
                      style='color: #000000; text-decoration: underline; font-weight: bold;'
                    >${data.supportEmail}</a></p>
                  <p>The ${data.appName} Team.</p>
                </div>
              </td>
            </tr>
          </table>

        </td>
      </tr>
    </table>
  </body>
</html>
    `;
  }
}
