import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { VerificationModalComponent } from "app/components/verification-modal/verification-modal.component";
import { AuthService } from "app/services/auth/auth.service";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"],
})
export class AdminLayoutComponent implements OnInit {
  verified = true;
  show = true;
  submittedVerification = false;

  constructor(private auth: AuthService, private matDialog: MatDialog) {}

  ngOnInit() {
    this.auth.user$.subscribe((data) => {
      console.log(data);
      this.verified = data.verified ? true : false;
      this.submittedVerification = data.requestedVerification ? true : false;
    });
  }

  requestVerification() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    let dialogRef = this.matDialog.open(
      VerificationModalComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }
}
